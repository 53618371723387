// Copyright (C) 2019 Pepperdata Inc. - All rights reserved.
// @flow

import React from "react";
import { requestResetPassword } from "../auth/auth-helpers";
import { CONTAINER_LOGIN, LOGIN_FORM_FORGET_PASSWORD } from "../constants";
import Button from "../ui-elements/button";
import Space from "../ui-elements/space";
import TextInput from "../ui-elements/text-input";
import { getEmailAddressValidationMessage } from "../utils";
import GoBackHeader from "./go-back-header";

type Props = {
  backButtonOnClick: () => void,
  uiFlow: string,
  containerFlow: string,
};

type State = {
  emailAddress: string,
  emailHasSend: boolean,
  isLoading: boolean,
  emailAddressValidationMsg: string,
};

class BodySectionForgetPassword extends React.Component<Props, State> {
  textInputRef: { current: null | React$ElementRef<typeof TextInput> };

  constructor(props: Props) {
    super(props);

    this.textInputRef = React.createRef();
    this.state = {
      emailAddress: "",
      emailHasSend: false,
      isLoading: false,
      emailAddressValidationMsg: "",
    };
  }

  setStateForUiBeginFetch = () => {
    this.setState({ isLoading: true, emailHasSend: false });
  };

  setStateForUiEndFetch = () => {
    this.setState({ isLoading: false, emailHasSend: true });
  };

  onSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { emailAddress } = this.state;

    const emailAddressValidationMsg =
      getEmailAddressValidationMessage(emailAddress);
    this.setState({ emailAddressValidationMsg });
    if (emailAddressValidationMsg.length > 0) {
      // do not send request if invalid email address
      return;
    }

    this.setStateForUiBeginFetch();
    return requestResetPassword(emailAddress)
      .then(() => {
        this.setStateForUiEndFetch();
      })
      .catch(() => {
        // do not show failed message to prevent exposing user's email addresses
        this.setStateForUiEndFetch();
      });
  };

  updateEmailAddress = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    this.setState({ emailAddress: value });
  };

  clearEmailAddress = () => {
    this.setState({ emailAddress: "" });
  };

  isThisComponentInView() {
    const { containerFlow, uiFlow } = this.props;
    return (
      containerFlow === CONTAINER_LOGIN && uiFlow === LOGIN_FORM_FORGET_PASSWORD
    );
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { uiFlow } = this.props;
    const { emailAddress } = this.state;

    // reset email validation error message once input field begins to update
    if (emailAddress !== prevState.emailAddress) {
      this.setState({
        emailAddressValidationMsg: "",
        emailHasSend: false,
      });
    }

    // when this UI is being viewed, focus on the input field
    if (this.isThisComponentInView() && prevProps.uiFlow !== uiFlow) {
      this.focusOnInput();
    }
  }

  focusOnInput = () => {
    if (this.textInputRef.current !== null) {
      this.textInputRef.current.focusOnInput();
    }
  };

  render() {
    const { backButtonOnClick } = this.props;
    const { emailAddress, emailHasSend, isLoading, emailAddressValidationMsg } =
      this.state;

    const label = "EMAIL ADDRESS";
    const buttonText = emailHasSend ? "RESEND" : "SEND";
    const heading = "Reset Your Password";
    const emailPostSendMessage = "Email sent. Didn’t get email? Click Resend.";
    const postSendMessageClassName = emailHasSend
      ? "pd-show-opacity"
      : "pd-hide-opacity";
    const componentVisibility = this.isThisComponentInView()
      ? "visible"
      : "hidden";

    return (
      <div
        className="body-section forget-password-body-section"
        style={{ visibility: componentVisibility }}
      >
        <GoBackHeader
          goBackActionFunc={backButtonOnClick}
          textToDisplay={heading}
        />

        <form
          className="input-section"
          onSubmit={this.onSubmit}
          autoComplete="off"
        >
          <Space height={44} />

          <TextInput
            ref={this.textInputRef}
            value={emailAddress}
            onChange={this.updateEmailAddress}
            onClear={this.clearEmailAddress}
            label={label}
            errorDescription={emailAddressValidationMsg}
            name="email"
            type="email"
            shouldFocusAfterTransitionDuration={true}
          />
          <Space height={19} />

          <Button text={buttonText} buttonType="submit" isLoading={isLoading} />
          <Space height="5px" />
          <div className={`post-send-message ${postSendMessageClassName}`}>
            {emailPostSendMessage}
          </div>
          <Space height="7px" />
        </form>
      </div>
    );
  }
}

export default BodySectionForgetPassword;
