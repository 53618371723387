// Copyright (C) 2019 Pepperdata Inc. - All rights reserved.
// @flow

import React from "react";
import BodySectionEmail from "./login-form-body-section-email";
import BodySectionPassword from "./login-form-body-section-password";
import BodySectionForgetPassword from "./login-form-body-section-forget-password";
import { signInWrapper } from "../auth/auth-helpers";
import {
  LOGIN_FORM_ACTION,
  LOGIN_FORM_SENTENCE,
  CREATE_FORM_ACTION,
  CREATE_FORM_SENTENCE,
} from "../constants";
import {
  canCreateNewAccount,
  isDemo,
  onEnterOrSpaceDownPropsBuilder,
} from "../utils";

type Props = {
  switchToCreate: () => void,
  containerFlow: string,
  uiFlow: string,
  goToUiLoginEmail: () => void,
  goToUiLoginPassword: () => void,
  goToUiForgetPassword: () => void,
};

type State = {
  emailAddress: string,
  password: string,
  authErrorMessage: string,
  isBottomSectionDisabled: boolean,
};

class LoginForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      emailAddress: "",
      password: "",
      authErrorMessage: "",
      isBottomSectionDisabled: false,
    };
  }

  clearAuthErrorMessage = () => {
    this.setState({ authErrorMessage: "" });
  };

  // Update emailAddress on input change
  updateEmailAddress = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    this.setState({ emailAddress: value });
    this.clearAuthErrorMessage();
  };

  clearEmailAddress = () => {
    this.setState({ emailAddress: "" });
    this.clearAuthErrorMessage();
  };

  // Update password on input change
  updatePassword = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    this.setState({ password: value });
    this.clearAuthErrorMessage();
  };

  clearPassword = (shouldClearErrorMessage: ?boolean = true) => {
    this.setState({ password: "" });

    if (shouldClearErrorMessage) {
      this.clearAuthErrorMessage();
    }
  };

  submitLoginEmail = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    // When successful, proceed to the next UI flow
    this.props.goToUiLoginPassword();
  };

  submitLoginPassword = (): Promise<any> => {
    const { emailAddress, password } = this.state;
    return signInWrapper(emailAddress, password)
      .then(() => {
        // No errors, reset error message.
        // The returned network response will redirect the page
        // to the desired logged in destination with the
        // exchanged pdToken set in the cookies.
        this.setState({ authErrorMessage: "" });
        return { wasSuccessful: true };
      })
      .catch((err) => {
        // Set error summary to state so we can display it
        console.error("Error submitting login credentials", err);

        let errorMessage = "An error has occurred. Please try again later.";
        if (err.message) {
          errorMessage = err.message;
        } else if (err.name) {
          errorMessage = err.name;
        }

        this.setState({ authErrorMessage: errorMessage });
        return { wasSuccessful: false };
      });
  };

  setBottomSectionDisability = (value: boolean) => {
    this.setState({ isBottomSectionDisabled: value });
  };

  render() {
    const {
      switchToCreate,
      containerFlow,
      uiFlow,
      goToUiLoginEmail,
      goToUiLoginPassword,
      goToUiForgetPassword,
    } = this.props;

    const {
      emailAddress,
      password,
      authErrorMessage,
      isBottomSectionDisabled,
    } = this.state;

    const bottomSectionClassNames = isBottomSectionDisabled ? "disabled" : "";
    const switchToCreateIfNotDisabled = isBottomSectionDisabled
      ? () => {}
      : switchToCreate;

    return (
      <div className={`pd-login-form ${uiFlow}`}>
        {/* Top Section */}
        <div className="top-section">
          <div>
            <span className="action">{LOGIN_FORM_ACTION}</span>{" "}
            <span>{LOGIN_FORM_SENTENCE}</span>
          </div>
        </div>

        {/* Body Section */}
        <div className="body-sections">
          <BodySectionEmail
            containerFlow={containerFlow}
            uiFlow={uiFlow}
            emailAddress={emailAddress}
            updateEmailAddress={this.updateEmailAddress}
            clearEmailAddress={this.clearEmailAddress}
            onSubmit={
              // $FlowFixMe returning ignored promise instead of undefined
              isDemo() ? this.submitLoginPassword : this.submitLoginEmail
            }
          />
          <BodySectionPassword
            containerFlow={containerFlow}
            uiFlow={uiFlow}
            emailAddress={emailAddress}
            password={password}
            updatePassword={this.updatePassword}
            clearPassword={this.clearPassword}
            onSubmit={this.submitLoginPassword}
            forgetPasswordOnClick={goToUiForgetPassword}
            authErrorMessage={authErrorMessage}
            backButtonOnClick={goToUiLoginEmail}
            setBottomSectionDisability={this.setBottomSectionDisability}
          />
          <BodySectionForgetPassword
            containerFlow={containerFlow}
            uiFlow={uiFlow}
            backButtonOnClick={goToUiLoginPassword}
          />
        </div>

        {/* Bottom Section */}
        {canCreateNewAccount() && (
          <div
            className={`bottom-section ${bottomSectionClassNames}`}
            onClick={switchToCreateIfNotDisabled}
            {...onEnterOrSpaceDownPropsBuilder(switchToCreateIfNotDisabled)}
          >
            <span className="action">{CREATE_FORM_ACTION}</span>{" "}
            <span>{CREATE_FORM_SENTENCE}</span>
          </div>
        )}
      </div>
    );
  }
}

export default LoginForm;
